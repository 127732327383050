$(window).scroll(function() {
    // scroll to top buttom

    var scrollToTop = $('.totop')

    if ($(window).scrollTop() >= 1000) {

        scrollToTop.addClass("show");

    } else {

        scrollToTop.removeClass("show");

    }



});

// $('.menu').click(function() {
//     $(this).toggleClass('open');
//     $('.main-nav-con').toggleClass('show')
// });


$(".menu").click(function() {
    $(this).toggleClass('open');
    $('.main-nav-con').slideToggle(500)
});


$(document).mouseup(e => {
    if (!$('.menu_item .menu_item_link').is(e.target) // if the target of the click isn't the container...
        &&
        $('.menu_item .menu_item_link').has(e.target).length === 0) // ... nor a descendant of the container
    {
        $('.menu_item .menu_item_link').removeClass('actColor');
        $('.menu_item .menu_item_link').next('.submenu').slideUp('acSubPro');
    }
});

$('.menu_item .menu_item_link').click(function() {
    $(this).toggleClass('actColor');
    $('.main-nav .menu_item .menu_item_link').not($(this)).removeClass('actColor');
})


$('.menu_item .menu_item_link ').click(function() {
        $(this).next('.submenu').slideToggle('acSubPro');
        $('.menu_item .menu_item_link ').not($(this)).next('.submenu').slideUp('acSubPro');
    })
    // $('.menu_item .menu_item_link ').click(function() {
    //     $(this).next('.submenu').toggleClass('acSubPro');
    //     $('.menu_item .menu_item_link ').not($(this)).next('.submenu').removeClass('acSubPro');
    // })


/*============== Start Dynamic Tab ==============*/
$('.tap_item').on('click', function() {
    $(this).addClass('tap_item_active').siblings().removeClass('tap_item_active');

    $('.content_tap > div').hide();
    $($(this).data('content')).fadeIn();
});
/*============== End Dynamic Tab ==============*/